/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import DataGrid from 'components/DataGrid';
import QueryBuilder from 'components/QueryBuilder';
import Divider from 'components/common/divider';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { isMobile } from 'lib/helpers/mobile';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { QueryDateRange, QueryItem, QueryRow } from 'utils/classes/query/query';
import { DataGridRowBase, DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import FundingRequestIncludedInvoicesMobile from '../FundingRequestIncludedInvoicesMobile';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';
import {
  FundingRequestIncludedInvoicesContainer,
  SearchAdvancedText,
  SearchContainer
} from './styled';

const queryItems: QueryItem[] = [];

export interface FundingRequestInvoiceViewModel {
  invoiceNumber: string;
  buyer: string;
  paymentDueDate: string;
  invoiceAmount: string;
  fundingCosts: string;
  purchasePrice: string;
  id: string;
  currencySymbol: string;
}

export interface FundingRequestTableData extends DataGridRowBase {
  invoiceNumber: string;
  buyer: string;
  paymentDueDate: string;
  invoiceAmount: number;
  fundingCosts: number;
  purchasePrice: number;
  currencySymbol: string;
}

export interface FundingRequestInvoiceViewModel {
  invoiceNumber: string;
  buyer: string;
  paymentDueDate: string;
  invoiceAmount: string;
  fundingCosts: string;
  purchasePrice: string;
  id: string;
  currencySymbol: string;
}

export interface FundingRequestIncludedInvoicesProps {
  invoices: FundingRequestInvoiceViewModel[];
  loading: boolean;
  isMaturing?: boolean;
  isHrefUrl?: boolean;
}

const FundingRequestIncludedInvoices: FC<FundingRequestIncludedInvoicesProps> = ({
  invoices,
  loading,
  isMaturing,
  isHrefUrl = false
}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [activeQueries, setActiveQueries] = useState<QueryRow[]>([]);
  const [paymentDueDateFromSearch, setPaymentDueDateFromSearch] = useState<Date | null>(null);
  const [paymentDueDateToSearch, setPaymentDueDateToSearch] = useState<Date | null>(null);

  useEffect(() => {
    filteredData();
  }, [JSON.stringify(activeQueries), searchTerm]);

  const headers = [
    new DataGridHeaderItem('Invoice Id', 'id', {
      display: false
    }),
    new DataGridHeaderItem(
      'Invoice Number',
      'invoiceNumber',
      new DataGridHeaderOptions(false, true, true)
    ),
    new DataGridHeaderItem('Buyer', 'buyer', new DataGridHeaderOptions(false, true, true)),
    new DataGridHeaderItem(
      'Maturity Date',
      'paymentDueDate',
      new DataGridHeaderOptions(false, true, true)
    ),
    new DataGridHeaderItem('Amount', 'invoiceAmount', {
      sort: true,
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p style={{ textAlign: 'right' }}>{value}</p>
      )
    }),
    new DataGridHeaderItem('Adjustments', 'adjustments', {
      sort: true,
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p style={{ textAlign: 'right' }}>{value}</p>
      )
    }),
    new DataGridHeaderItem('Fees', 'fees', {
      sort: true,
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p style={{ textAlign: 'right' }}>{value}</p>
      )
    }),
    new DataGridHeaderItem('Net amount', 'netAmount', {
      sort: true,
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p style={{ textAlign: 'right' }}>{value}</p>
      )
    })
  ];

  if (!isMaturing)
    headers.push(
      ...[
        new DataGridHeaderItem('Funding Costs', 'fundingCosts', {
          sort: true,
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
            <p style={{ textAlign: 'right' }}>{value}</p>
          )
        }),
        new DataGridHeaderItem('Purchase Price', 'purchasePrice', {
          sort: true,
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
            <p style={{ textAlign: 'right' }}>{value}</p>
          )
        })
      ]
    );

  const activeQueriesHandler: (queries: QueryRow[]) => void = (queries) => {
    setActiveQueries(queries);
    setFilterCriteria(queries);
  };

  const setFilterCriteria: (queries: QueryRow[]) => void = (queries) => {
    const createdDateFilterIndex = queries.findIndex((q) => q.fieldValue === 'paymentDueDate');
    if (~createdDateFilterIndex) {
      setPaymentDueDateFromSearch(
        (queries[createdDateFilterIndex].queryValue as QueryDateRange).from || null
      );
      setPaymentDueDateToSearch(
        (queries[createdDateFilterIndex].queryValue as QueryDateRange).to || null
      );
    }
  };

  const advancedSearchClickHandler: () => void = () => setShowAdvancedSearch(!showAdvancedSearch);

  const filterByCreatedDate: (data: DataGridRowItem[]) => DataGridRowItem[] = (data) => {
    if (!paymentDueDateFromSearch || !paymentDueDateToSearch) return data;

    const fromDate: DateTime = DateTime.fromObject({
      year: paymentDueDateFromSearch.getFullYear(),
      month: paymentDueDateFromSearch.getMonth() + 1,
      day: paymentDueDateFromSearch.getDate()
    });

    const toDate: DateTime = DateTime.fromObject({
      year: paymentDueDateToSearch.getFullYear(),
      month: paymentDueDateToSearch.getMonth() + 1,
      day: paymentDueDateToSearch.getDate()
    });

    return data.filter((d) => {
      if (!d.paymentDueDate) return true;
      const paymentDueDateTime: DateTime = DateTime.fromFormat(d.paymentDueDate, _DATE_FORMAT);

      const paymentDueDateTimeObj: DateTime = DateTime.fromObject({
        year: paymentDueDateTime.year,
        month: paymentDueDateTime.month,
        day: paymentDueDateTime.day
      });
      return paymentDueDateTimeObj >= fromDate && paymentDueDateTimeObj <= toDate;
    });
  };

  const filteredData: () => DataGridRowItem[] = () => {
    const fuzzySearchResults = (invoices as unknown as DataGridRowItem[]).filter(
      (r) =>
        (r.invoiceNumber as string).toLowerCase().includes(searchTerm.toLowerCase()) ||
        (r.buyer as string).toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const createdDateFilterResults = filterByCreatedDate(fuzzySearchResults);
    return createdDateFilterResults;
  };

  const rowClickHandler: (row: any) => void = (row) => {
    let data: any = isMobile() ? row : row[0];
    isHrefUrl ? (window.location.href = `/invoices/${data}`) : navigate(`/invoices/${data}`);
  };

  const searchHandler: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void = (event) => setSearchTerm(event.currentTarget.value);

  return (
    <FundingRequestIncludedInvoicesContainer>
      <SearchContainer>
        <OutlinedInput
          id="outlined-adornment-search"
          dataTestId="sp-input-fuzzy-search"
          defaultValue={searchTerm}
          changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            searchHandler(event)
          }
          startAdornment={
            <InputAdornment
              data-automation-id="early-payment-request-div-included-invoices-input-adornment"
              position="start"
            >
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search by invoice number and buyer"
          dataAutomationId="early-payment-request-input-included-invoices-fuzzy-search"
          width={`${isMobile() ? '100%' : '400px'}`}
        />

        {/* {!isMobile() && (
          <SearchAdvancedText
            onClick={advancedSearchClickHandler}
            data-automation-id="early-payment-request-p-advanced-search-text"
            data-testid="sp-advanced-search"
          >
            {showAdvancedSearch
              ? 'Hide advanced search'
              : `Advanced search ${activeQueries.length > 0 ? `(${activeQueries.length})` : ''}`}
          </SearchAdvancedText>
        )} */}
      </SearchContainer>
      {/* <QueryBuilder
        queryItems={queryItems}
        activeQueriesHandler={activeQueriesHandler}
        closeClickHandler={advancedSearchClickHandler}
        open={showAdvancedSearch}
        testingTagPage="early-payment-request"
      /> */}
      <Divider />
      {!isMobile() && (
        <DataGrid
          headers={headers}
          data={filteredData()}
          ariaLabel="Data Table"
          ariaCaption="A table that shows the invoices included in the selected funding request."
          totalRows={invoices.length}
          loading={loading}
          exportFilename={''}
          rowClickHandler={rowClickHandler}
          fixedHeader
          dataAutomationTag="early-payment-request-table-included-invoices"
        />
      )}
      {isMobile() && <FundingRequestIncludedInvoicesMobile invoices={filteredData()} />}
    </FundingRequestIncludedInvoicesContainer>
  );
};

export default FundingRequestIncludedInvoices;
